exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculator-js": () => import("./../../../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-campaign-creator-js": () => import("./../../../src/pages/campaign_creator.js" /* webpackChunkName: "component---src-pages-campaign-creator-js" */),
  "component---src-pages-campaign-preview-js": () => import("./../../../src/pages/campaign_preview.js" /* webpackChunkName: "component---src-pages-campaign-preview-js" */),
  "component---src-pages-index-1-js": () => import("./../../../src/pages/index_1.js" /* webpackChunkName: "component---src-pages-index-1-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invest-js": () => import("./../../../src/pages/invest.js" /* webpackChunkName: "component---src-pages-invest-js" */),
  "component---src-pages-investor-onboarding-js": () => import("./../../../src/pages/investor_onboarding.js" /* webpackChunkName: "component---src-pages-investor-onboarding-js" */),
  "component---src-pages-simulation-js": () => import("./../../../src/pages/simulation.js" /* webpackChunkName: "component---src-pages-simulation-js" */)
}

